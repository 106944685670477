
#AppHeader {
    /*background-image: url("../../assets/img/app-banner.png");*/
    height: 120px;
    background-size: 100% 220px;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: 1000;
    font-family: proxima-nova, sans-serif;

}


#NavBar {

    background-image:
        url('../assets/img/ExactAQ_Web_App_Header.png'),
        linear-gradient(0deg, transparent 14px,transparent 14px),
        linear-gradient(0deg, transparent 12%,#259cd7 12%);




    background-repeat: no-repeat;
    margin-bottom: 0px;
    height: 105px;
    width: 100%;
    overflow-y: visible;
    z-index: 1001;
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
    border-radius: 0;

}

#NavBar a {
    color: black;
    text-decoration: none;
}

.link-wrapper {
    height: 50%;
    width: 30%;
    font-size: 32px;
}

#NavBar .custom-navbar-links a {
    text-align: center;
    cursor: pointer;
    border-radius: 0px;
    font-size: 20px;
    background: none;
    text-shadow: none;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
}

#NavBar #AppLogo a:hover, #NavBar #AppMessage a:hover {
    border-top: none !important;
}


#NavBar.mobile-nav a{
    margin-top: 0;
    margin-left: -10px;
}

#NavBar.mobile-nav #AppLogo a:hover, #NavBar.mobile-nav #AppMessage a:hover {
    border-top: none !important;
}

#AppMessage {
    width: 400px;
}

#AppMessage p {
    font-size: 16px;
    font-weight: bold;
    color: black;
}

#AppMessage a.logo-link {
    padding-left: 10px;
    display: inline-block;
}

#AppLogo {
    width: 100%;
    height: 70%;
    padding: 10px 60px 0px 0;
    display: flex;
    align-items: center;
    background: transparent;
}

.navbar-header-custom {
    height: 100%;
}

.nav-logo {
    height: 60px;
    width:300px;
    margin-bottom: 5px;
    margin-left: 25px;
    background-image: url('../assets/img/ExactAQ_Logo_small.png') ;
    background-repeat: no-repeat;
    background-size:auto 100%;
}

.home-link {
    width: 295px;
    padding-left: 30px;
    font-size: 28px;
    color: black !important;
}
.NavBrandSubTitle {
    width: 295px;
    padding-left: 30px;
    font-size: 18px;
    color: black;
}

.desktop-navbar-placeholder {
    width: 205px;
}

.logo-link {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-nav .logo-text {
    margin-bottom: 8px;
    font-size: 14px;
}

.mobile-nav .navbar-nav li {
    background-color: lightgrey;

}

.mobile-nav .navbar-nav li:hover {
}

.logo-text{
    color: white ;
    font-family: "Inter-ExtraBold", sans-serif;
    font-size: 20px;
    width:300px;
    min-width: 265px;
}

#NavBar.mobile-nav {
    padding: 8px 0;
}

.navbar-default .navbar-toggle {
    background-color: lightgrey;
    border: none;
    float: left;
    margin-right: 0;
    margin-left: 10px;
}

.navbar-default .navbar-toggle:focus  {
    background-color: lightgrey;
}

.navbar-default.navbar-custom {
    /*background-color: transparent*/
    background-image: none;
    border: none;
    box-shadow: none;
}

.navbar-collapse.collapse {
    transition: height 0.2s;
}
.navbar-collapse.collapsing {
    height: 0 !important;
}
.navbar-collapse.collapse.in {
    max-height: none;
    height: 100%!important;
    border-color: inherit;
    padding: 0;
    display: flex!important;
    justify-content: right;
    margin-right: 30%;
}

.navbar-nav, .navbar-nav li {
    height: 100%;
}

.notification-icon {
    width: 50px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: -12px;
}

.notification-nav-link {
    margin-top: 2%;
}

.navbar-nav li:hover, #ResourcesLink:hover {
    background-color: #60A8E6;
}

#ResourcesDropdown,
#DataDropdown {
    background-color: white;
}

#ResourcesDropdown li:hover,
#DataDropdown li:hover {
    background-color: #60A8E6;
}

#ResourcesDropdown .resource-link a,
#DataDropdown .resource-link a {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
}

.mobile-flex-wrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.mobile-navbar-placeholder {
    width: 49px;
}

.badge-pill {
    background-color: #4d964d;
    border-radius: 15px;
    margin-left: 5px;
    height: 20px;
    padding-top: 4px;
    font-size: 13px;
}

.notification-link-text {
    margin-top: 2px;
}

button.navbar-toggle {
    background-color: white;
}

@media (min-height: 1600px) {
    .logo-text {
        font-size: 20px;
    }
}

@media (min-width: 768px) {
    @media (min-width: 768px) {
        .navbar .navbar-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 50%;
            float: right;
            vertical-align: top;
        }

        .navbar .navbar-collapse {
            text-align: center;
        }
    }
}

/* This should match logic of isMobile width in parent component */
@media only screen and (max-width: 767px) {

    #AppHeader {
        height: 105px;
    }

    #NavBar .navbar-collapse {
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }

    #NavBar .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }

    #NavBar .navbar-collapse.collapse {
        display: none!important;
    }

    #NavBar .navbar-nav {
        float: none!important;
        margin: 0;
    }

    #NavBar .collapse.in{
        display:block !important;
    }

    #NavBar .navbar-collapse.collapse.in {
        height: auto!important;
        overflow: visible;
        margin-top: 9px;
        box-shadow: -1px 10px 10px 1px #454647;
    }

    #NavBar .custom-navbar-links a {
        min-height: 40px;
    }

    #NavBar .notification-nav-link {
        margin-top: 0;
    }

    .notification-link-text {
        margin-top: 0;
    }
}

@media only screen and (max-width: 360px) {
    #AppMessage {
        width: 140px;
    }

    #AppMessage p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 360px) {
    #AppLogo {
        width: 110px;
    }

    #AppMessage {
        display: none;
    }
}
