@font-face{
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/inter/Inter-Regular.ttf') format('truetype');
    src: local(''),
    url('../fonts/inter/Inter-Regular.ttf') format('truetype');
}

@font-face{
    font-family: 'Inter-Bold';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/inter/Inter-Bold.ttf') format('truetype');
    src: local(''),
    url('../fonts/inter/Inter-Bold.ttf') format('truetype');
}

@font-face{
    font-family: 'Inter-ExtraBold';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/inter/Inter-ExtraBold.ttf') format('truetype');
    src: local(''),
    url('../fonts/inter/Inter-ExtraBold.ttf') format('truetype');
}