.ol-popup {
   position: absolute;
   background-color: white;
   box-shadow: 0 1px 4px rgba(0,0,0,0.2);
   padding: 15px 15px 0 15px;
   border-radius: 10px;
   border: 1px solid #cccccc;
   bottom: 12px;
   left: -50px;
   min-width: 300px;
   font-family: "Inter-Regular", "sans-serif";
}

.ol-popup:after, .ol-popup:before {
   top: 100%;
   border: solid transparent;
   content: " ";
   height: 0;
   width: 0;
   position: absolute;
   pointer-events: none;
}
.ol-popup:after {
   border-top-color: white;
   border-width: 10px;
   left: 48px;
   margin-left: -10px;
}
.ol-popup:before {
   border-top-color: #cccccc;
   border-width: 11px;
   left: 48px;
   margin-left: -11px;
}

.ol-popup-closer {
   text-decoration: none;
   position: absolute;
   top: 2px;
   right: 8px;
}
.ol-popup-closer:after {
   content: "✖";
}

.popup-content{
   width: 100%;
   height: 100%;
   margin: auto;
   font-family: "Inter-Regular", sans-serif;
}
.title-row{
   text-align: center;
   width:90%;
   height:30%;
   margin-bottom: 10px;
}
.concentration-row{
   display:flex;
   justify-content: space-between;
}
.slider-start-text{
   width:5%;
   text-align: left;
   font-size: 12px;
   margin-top: -4px;
}
.slider-end-text{
   width:5%;
   text-align:right;
   font-size:12px;
   margin-bottom: 10px;
   margin-top: -4px;
}
.concentration-slider{
   text-align: center;
   height:10px;
   border-radius:5px;
   background: linear-gradient( 90deg,
   rgba(0,120,191,1) 0%,
   rgba(44,164,221,1) 0.887%,
   rgba(73,201,245,1) 1.77%,
   rgba(69,184,85,1) 2.66%,
   rgba(46,136,65,1) 3.55%,
   rgba(225,205,23,1) 4.04%,
   rgba(225,201,23,1) 8.99%,
   rgba(225,198,23,1) 12.13%,
   rgba(243,134,9,1) 15.96%,
   rgba(243,126,9,1) 17.52%,
   rgba(246,119,8,1) 20.22%,
   rgba(255,111,0,1) 22.92%,
   rgba(255,81,0,1) 24.94%,
   rgba(255,51,0,1) 32.36%,
   rgba(255,34,0,1) 40.45%,
   rgba(255,21,0,1) 48.09%,
   rgba(123,13,219,1) 56.40%,
   rgba(114,14,196,1) 67.42%,
   rgba(98,13,168,1) 78.65%,
   rgba(87,14,152,1) 89.89%,
   rgba(145,44,23,1) 100% );
   width:80%;
   margin-bottom: 10px;
   display: flex;
   align-content: center;
}
.concentration-ball{
   width:16px;
   height:16px;
   margin-top: -3px;
   border-radius: 100%;
   border: 1px solid black;
}

.bottom-row {
   text-align: center;
   justify-content: space-between;
   display: flex;
   width:120%;
   height:30%;
   margin-left:-10%;

}

.concentration-value{
   text-align: left;
   margin: auto;
   float:left;
   font-size: 12px
}

.reverse-geocode-result{
   text-align: right;
   margin: auto;
   float:right;
   font-size: 12px;
}

.spinner-container{
   width:400px;
   height: 200px;

}

.fa-spin{

}

