.ol-map {
   position:absolute;
    top: 92px;
    bottom: 0;
    left: 0;
    right: 0;
}


.ol-control{

    background-color: rgba(255,255,255,0.4);
    border-radius: 4px;
    padding: 2px;
    float: right;
}

.ol-full-screen {
    bottom: auto;
    right: 15px;
    top: 15px;
}

.ol-zoom {
    margin-top: 5%;
    right: 15px;
    left: auto;
}

.ol-zoomslider {
    margin-top: 5%;
    right: 15px;
    left: auto;
}

.ol-scale-line{
    bottom: 5px;
    right: 325px;
    left: auto;
}

@media only screen and (max-width:1340px){
    .ol-scale-line{
        bottom: 35px;
        right: 25px;
        left: auto;
    }
}

@media only screen and (max-width:1175px){
    .ol-attribution{
        width:175px;
        text-align: left;
    }

    .ol-scale-line{
        bottom: 45px;
        right: 25px;
        left: auto;
    }
}