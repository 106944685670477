
.time-selector-card{
    background-color: rgba(255,255,255,50%);
    border-radius: 32px;
    position: fixed;
    z-index: 2;
    bottom: 0;
    margin: 15px 15px 15px 400px;
    height: 90px;
    width:700px;
    display: block;
    font-family: "Inter-Regular", "sans-serif";

    -webkit-transition: width .5s ease-in-out;
    -moz-transition: width .5s ease-in-out;
    -o-transition: width .5s ease-in-out;
    transition: width .5s ease-in-out;
}

.timeline-container-title{
    padding-left: 115px;
    align-content: center;
    display: flex;
    justify-content: center;

}
.time-line-group {
    margin-top:-37px;
    margin-left: 100px;
    text-align: center;
    display: flex;
    position:absolute;
    width:400px

}

.control-group{
    display: flex;
    justify-content: space-between;
    margin: 10px 10px 10px 10px;
    width: 80px;
    height:25px;
    space-between: 5px;
}
.time-line-container{
    justify-content: center;
    display: flex;
    position: fixed;
    width:400px;
    margin-bottom: 115px;
}

.time-line{
    width:80%;
    height: 20px;
    border-top-style: solid;
    margin-top: 30px;
    display: flex;
    border-width: 3px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
}

.time-line-left-hash{
    width: calc(1/8 * 100%);
    height: 10px;
    border-left-style: solid;
    border-width: 2px;
    background: linear-gradient(#666, #666) no-repeat top/2px 75%;
    display: flex;

}
.time-line-right-hash{
    width: calc(1/8 * 100%);
    height: 10px;
    border-right-style: solid;
    border-left-style: solid;
    border-width: 2px;
    background: linear-gradient(#666, #666) no-repeat top/2px 75%;
    display: flex;
}

.time-line-center-hash{
    height: 10px;
    width: calc(1/2 * 100%);
    background: linear-gradient(#666, #666) no-repeat top/1px 50%;
}
.time-line-label-container{
    width:83%;
    height: 20px;
    margin-top: 50px;
    display: flex;
    padding-left: -5px;
    padding-right: -5px;
    position: absolute;
    justify-content: space-between;
}
.time-line-label{
    font-size: 10px;
}

.slider-thumb{
    background-color: orange;
    height:15px;
    width:15px;
    border-radius:100%;
    border: 1px solid white;
    font-size: 0px;
}

.horizontal-slider{
    width:80%;
    height:10px;
    position: relative;
    margin-top: 22.5px;
}

.button-group{
    display: inline-flex;
    width:200px;
    justify-content: space-between;
    align-content: center;
    margin-top: 25px;
    margin-left: 10px;
    z-index: 3;
}
.control-group{
    width: 110px;
}
.time-increment-button {
    height: 30px;
    width: 30px;
    background-color: rgba(255,255,255,.755);
    border-radius: 100%;
    border: 1px solid rgba(0,0,0,.25);
    display: inline-flex;
    position:relative;
    justify-content: center;
    align-items: center;
    color:black;
    font-size: 16px !important;
}

.calendar-button{
    height: 30px;
    width: 30px;
    background-color: rgba(255,255,255,.75);
    border-radius: 100%;
    border: 1px solid rgba(0,0,0,.25);
    color:black;
    display: inline-flex;
    position:relative;
    justify-content: center;
    align-items: center;
}

.calendar-button:hover{
    cursor: pointer;
    background-color: #eee;
}

.time-increment-button:hover{
    cursor: pointer;
    background-color: #eee;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.forecast-toggle-group{
    animation: fadeIn ease-in .5s;
    margin-top:-28px;
    position:relative;
    float: right;
    display: flex;
    flex-direction:column;
    font-size: 16px;
    margin-right: 20px;
}

.forecast-toggle-title{
    margin: 0 auto;
    margin-top: -10px;
}

.forecast-toggle-control-group{
    font-size:14px;
    display: inline-flex;
    width:200px;
    justify-content: space-evenly;
    align-content: center;
    margin-top: 10px;
    margin-left: 10px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #666;
    -webkit-transition: .4s;
    transition: .4s;

}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 17px;
}

.slider.round:before {
    border-radius: 50%;
}


@media only screen and (max-width:1070px){
    .time-selector-card{
        margin: 15px 15px 15px 350px;
    }
}



