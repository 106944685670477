

.attribution-header{
    font-family: "Inter-Bold", sans-serif;
    display: flex;
    text-align: center;
    font-size: 32px;
}

.attribution-item{
    margin-top: 1%;
    margin-left: 1%;
    font-size: 16px;
}