html,body{
    height:100%;
}

.side-panel-expanded {
    height:calc(100% - 90px);
    width: 18%;  /* TBD: Why not just explicitly set the width to 325px on non-mobile screens? */
    max-width: 375px;
    min-width: 325px;
    position: absolute;
    overflow-x: hidden;
    z-index: 2;
    left: 0;
    background-color: rgba(255,255,255,50%);
    transition: 0.3s;
    box-shadow: -1px 2px 10px 1px #454647;
    margin-top: -15px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.side-panel-minified{
    width: 18%;
    min-width: 140px;
    height:100%;
    position: fixed;
    z-index: 2;
    left: 0;
    background-color: transparent;
    padding: 6px 18px 0px 17px;
}

.side-panel-button {
    position: absolute;
    color: black;
    margin-left:80%;
    margin-top: -38px;
    padding-bottom: 40px;

}

.side-panel-button-collapsed{
    margin-top: 10px;
    color: black;
    padding: 10px;
    border-radius: 6px;
}

.side-panel-button:hover{
    cursor: pointer;
}

.arrow-right {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
}

.arrow-left {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #666;
}

.selector-label {
    min-width: 280px;
    text-align:left;
    padding-left: 5.5%;
    font-weight: bold;
    font-family: "Inter-Regular", "sans-serif";
}

.selector-container{
    margin:18% 5% 5% 5%;
}
.selector-item{
    margin-top: 5%;
}

.form-check{
    border-radius: 16px;
    background-color: rgba(255,255,255,75%);
    width: 100%;
    font-size:18px;
    height: 30px;
    padding: 2px 5% 2px 5%;
    margin-top: 5px;
    font-family: "Inter-Regular", sans-serif;
}

.form-select{
    border-radius: 16px;
    background-color: rgba(255,255,255,75%);
    width: 100%;
    font-size:18px;
    height: 30px;
    padding: 0 0 0 16px !important;
    font-family: "Inter-Regular", sans-serif;
}

.location-search-box{
    border: 2px solid #666;
    border-radius: 16px;
    background-color: rgba(255,255,255,75%);
    width: 100%;
    font-size:18px;
    font-family: "Inter-Regular", sans-serif;
    height: 30px;
    padding: 5% 5% 5% 5%;
    marigin-left: 10px;
}

.location-select-button{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.location-select-button:hover{
    cursor: pointer;
    background-color: #eee;
}

.search-dropdown{
    border: 2px solid darkgray ;
    border-radius: 6px;
    width: 90%;
    font-size:18px;
    height: fit-content;
    padding: 0 0 0 5%;
    position:absolute;
    background-color: white;
    z-index: 5;

}

.form-check-input{
    float: right !important;
}

.option{
    font-size:16px;
}
.color-ramp-container{
    padding: 0% 2% 2% 2%;
    marigin-left: 10px;
    margin-top: -30px;
    font-family: "Inter-Regular", sans-serif;
}


.concentration-gradient{
    text-align: center;
    height:20px;
    border-radius:5px;
    background: linear-gradient( 90deg,
    rgba(0,120,191,1) 0%,
    rgba(44,164,221,1) 0.887%,
    rgba(73,201,245,1) 1.77%,
    rgba(69,184,85,1) 2.66%,
    rgba(46,136,65,1) 3.55%,
    rgba(225,205,23,1) 4.04%,
    rgba(225,201,23,1) 8.99%,
    rgba(225,198,23,1) 12.13%,
    rgba(243,134,9,1) 15.96%,
    rgba(243,126,9,1) 17.52%,
    rgba(246,119,8,1) 20.22%,
    rgba(255,111,0,1) 22.92%,
    rgba(255,81,0,1) 24.94%,
    rgba(255,51,0,1) 32.36%,
    rgba(255,34,0,1) 40.45%,
    rgba(255,21,0,1) 48.09%,
    rgba(123,13,219,1) 56.40%,
    rgba(114,14,196,1) 67.42%,
    rgba(98,13,168,1) 78.65%,
    rgba(87,14,152,1) 89.89%,
    rgba(145,44,23,1) 100% );
    width:85%;

}
.concentration-start-text{
    width:5%;
    text-align: left;
    font-size: 12px;
    margin-top: 0px;
}
.concentration-end-text{
    width:5%;
    text-align:right;
    font-size:12px;
    margin-bottom: 10px;
    margin-top: 0px;
}

.tick-marks{
    margin-top: 50px;
    marigin-left: 10px;
    height: 20px;
    display: flex;
    margin-bottom: -6px;
    width: 100%;
    justify-content: center;


}
.tick-mark{
    width: calc(1/8 * 100%);
    height: 10px;
    border-width: 2px;
    background: linear-gradient(black, black) no-repeat top/2px 75%;
    display: flex;
}

.point-info-container{
    border: 2px solid #666 ;
    border-radius: 8px;
    background-color: rgba(255,255,255,75%);
    width:90%;
    height:180px;
    margin: auto;
    font-family: "Inter-Regular", sans-serif;
}

.concentration-circle{
    margin-top:20px;
    margin-left: 8px;
    width:32px;
    height:32px;
    border-radius: 100%;
    border: 2px solid black;
}
.point-concentration-row{
    display:flex;
    justify-content: flex-start;
    padding: 5% 5% 5% 5%;
    align-content: center;
}

.concentration-text-header{
    font-size: 32px;
    text-align: center;
}
.location-row{
    padding: 5% 5% 5% 5%;
}

.place-text{
    font-size: 20px;
    display:block;
    white-space:nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.symbol-container{
    font-size: 16px;
    color:gray;
    margin-left: -100px;


}
.form-check-label{
    width: 80%;
}

.label-flex{
    display: flex;
}
.measurement-text{
 width: 210px;
}

.time-line-group{
    margin-left:20%;
}

.bottom-container{
 margin:0% 5% 5% 5%;
}

.attribution-container{
    overflow-y: visible;
    overflow-wrap: normal;
    padding: 4px 4px 4px 16px !important;
    font-family: "Inter-Regular", "sans-serif";
    background-color: rgba(255,255,255,75%);
    font-size:16px;
    border-radius: 8px;
    z-index:2;

}

.layer-tooltip {
    position: relative;
    display: inline-block;
    margin-left: 10px;
}

.layer-tooltip .layer-tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #666;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    bottom: 107%;
    left: 0px;
}

.layer-tooltip .layer-tooltiptext::after {
    position: absolute;
    top: 100%;
    left: 50%;
    padding-left: 20px;
    padding-right: 10px;
    border-width: 10px;
    border-style: solid;
    border-color: #212529 transparent transparent transparent;
}

.layer-tooltip:hover .layer-tooltiptext {
    visibility: visible;
    opacity: 1;
}

.layer-tooltip:hover {
    background-color: rgba(236,236,236,1);
    height:26px;
    width:100%;
    margin-left: -12px;
    padding-left: 22px;
    border-radius: 12px;
}